<template>
  <div
    class="popup-container align-items-center"
    v-show="blnAltStatusunsucess == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnAltStatusunsucess == true"
        :altMsgBox="$t('174')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('214')"
        :strButtonCancel="$t('215')"
        @confirm="blnAltStatusunsucess = false"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltStatusunsucess == true"
        @click="fetchUserfields()"
      ></div>
    </transition>
  </div>
  <div class="preview" :style="processData == true ? 'height: 1000px;' : ''">
    <skeleonright v-if="processData == true" />
    <div v-if="processData == false">
      <p class="mb-0 typeName text-primary border-bottom pb-2">
        <!-- {{ strTemplateType }} -->
        {{ strTemplateName }}
      </p>
      <div class="mt-4">
        <div class="d-flex w-100 m-0">
          <div
            class="bg-white px-3 py-4 shadow-sm rounded left-menu me-3"
            style="height: fit-content"
          >
            <p class="text-primary fnt-medium mb-4">{{ $t("910") }}</p>
            <div
              class="mb-4 menu"
              style="width: 200px"
              v-for="(item, index) in listMenu"
              :key="index"
              @click="selectMenu(item.intGroupID, index)"
            >
              <span
                class="fnt-light mx-2"
                :class="
                  intGroupID == item.intGroupID ? 'text-primary fnt-medium' : ''
                "
                >{{ $t(item.strGroupName) }}</span
              >
            </div>
          </div>
          <div class="bg-white shadow-sm rounded w-75" v-if="blnLoading">
            <Loading1 />
          </div>
          <div
            class="rounded right-menu"
            style="width: 1200px"
            v-if="!blnLoading"
          >
            <div
              class="fnt-medium bg-white px-5 py-4 fnt-bold fnt-title text-dark w-100 shadow-sm rounded-top border-bottom d-flex justify-content-between"
            >
              {{ $t(listUserFields[TypeName - 1][intindexOfGroupID].formName) }}

              <Label
                :label="$t('34')"
                class="mb-3 text-danger-500 fnt-regular fnt-caption"
              />
            </div>
            <div
              class="bg-white p-5 mb-5 rounded-bottom shadow-sm"
              style="height: fit-content"
            >
              <InputAddGroupList
                :formData="listUserFields[TypeName - 1][intindexOfGroupID]"
                :blnDisable="false"
                :userInfo="mockupUserInfo[this.intGroupID]"
              />
              <!-- <Button
                class="btn btn-primary"
                :name="$t('929')"
                @input="addData()"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Button from "@/components/button/button.vue";
import skeleonright from "@/components/form/Template/SkeletonRight.vue";
import InputAddGroupList from "@/components/input/InputAddGroupList.vue";
import Label from "@/components/label/label.vue";
import Loading1 from "@/components/Skeleton.vue";
import {
  listUserFieldsID,
  listUserFields,
  mockupUserInfo,
} from "@/typescript/templateData.ts";
import apitemplate from "@/typescript/urlapi/adminsystem/APITemplate";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import urlapi from "@/typescript/urlapi/url";
import axios from "axios";
export default {
  components: {
    skeleonright,
    InputAddGroupList,
    Label,
    Loading1,
  },
  props: {
    TypeName: { type: String },
    intMemberID: { type: Number },
  },
  data() {
    return {
      strTemplateType: "",
      listMenu: [],
      intGroupID: 1,
      intindexOfGroupID: 0,
      processData: true,
      listUserFieldsID: listUserFieldsID,
      listUserFields: listUserFields,
      mockupUserInfo: {},
      blnAltStatusunsucess: false,
      strTemplateName: "",
      blnLoading: false,
    };
  },
  mounted() {
    if (this.TypeName == 1) {
      this.strTemplateType = "Thai student template default";
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911" },
        { intGroupID: 2, strGroupName: "912" },
        { intGroupID: 3, strGroupName: "913" },
        { intGroupID: 4, strGroupName: "914" },
        { intGroupID: 5, strGroupName: "915" },
        // ข้อมูลบัณฑิตยังไม่มีใน phase 1
        // { intGroupID: 6, strGroupName: "916" },
      ];
    } else if (this.TypeName == 2) {
      this.strTemplateType = "Foreign student template default";
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911" },
        { intGroupID: 2, strGroupName: "913" },
        { intGroupID: 7, strGroupName: "912" },
        {
          intGroupID: 8,
          strGroupName: "993",
        },
      ];
    } else if (this.TypeName == 3) {
      this.strTemplateType = "Staff template default";
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911" },
        { intGroupID: 9, strGroupName: "1003" },
      ];
    }
    // ชื่อจากหน้าบันทึกข้อมูล template
    this.strTemplateName = this.$route.query.strTemplateName;
    // เปลี่ยนชื่อ tab
    document.title = this.strTemplateName;
    this.processLoadingData();
  },
  methods: {
    async processLoadingData() {
      this.processData = true;
      await this.fetchUserfields();
      await this.fetchUserInfo();
      setTimeout(() => {
        this.processData = false;
      }, 1000);
    },
    async selectMenu(intGroupID, indexOfGroupID) {
      this.blnLoading = true;
      this.intGroupID = intGroupID;
      this.intindexOfGroupID = indexOfGroupID;
      // console.log(indexOfGroupID);

      await this.fetchUserfields();
      await this.fetchUserInfo();
      setTimeout(() => {
        this.blnLoading = false;
      }, 1000);
    },
    async fetchUserfields() {
      // console.log("res");
      // console.log(this.TypeName);
      try {
        // apitemplate
        const res = await axios.post(
          urlapi + apitemplate.getUserFields,
          this.listUserFieldsID[this.TypeName - 1][this.intindexOfGroupID]
        );
        // console.log(res);
        this.listUserFields[this.TypeName - 1][
          this.intindexOfGroupID
        ].fieldsGroup.forEach((group, intGroupIndex) => {
          group.fieldList[0].forEach((row, intRowIndex) => {
            row.forEach((column, intColumnIndex) => {
              res.data.dataFields.forEach((field) => {
                if (field.strID == column.strID) {
                  this.listUserFields[this.TypeName - 1][
                    this.intindexOfGroupID
                  ].fieldsGroup[intGroupIndex].fieldList[0][intRowIndex][
                    intColumnIndex
                  ] = field;
                  // console.log(this.listUserFields[0][this.intGroupID - 1].fieldsGroup[intGroupIndex].fieldList[intRowIndex][intColumnIndex]);
                }
              });
            });
          });
        });
        if (res.data.code == 500) {
          this.blnAltStatusunsucess = true;
        }
        // console.log(res.data.dataFields);
      } catch (error) {
        this.blnAltStatusunsucess = true;
      }
    },
    async fetchUserInfo() {
      try {
        // console.log(this.intMemberID);
        // if (this.intMemberID != null) {
        const res = await axios.get(
          urlapi + apiuserinfo.LoadUserinfobyID + 13,
          {
            params: { UserInfoID: this.intGroupID },
          }
        );
        console.log(res);
        console.log(mockupUserInfo);

        // this.mockupUserInfo[this.intGroupID] = res.data.data.userinfo;
        // }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  .typeName {
    font-weight: 500;
    font-size: 24px;
    line-height: 38px;
  }

  .menu {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
