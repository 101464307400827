<template>
  <div
    class="
      pt-5
      px-5
      bg-white
      rounded-2
      shadow-sm
      con
      w-100
      h-100
      overflow-hidden
    "
  >
    <div class="d-flex justify-content-between align-items-end mb-4">
      <div class="left d-flex">
        <div
          style="width: 248px; height: 48px"
          class="me-3 rounded-2 skeleton-item bg-black-200"
        ></div>
        <div
          style="width: 248px; height: 48px"
          class="me-3 rounded-2 skeleton-item bg-black-200"
        ></div>
        <div
          style="width: 100px; height: 48px"
          class="me-3 rounded-2 skeleton-item bg-black-200"
        ></div>
      </div>
      <div
        style="width: 100px; height: 16px"
        class="me-3 rounded-2 skeleton-item bg-black-300"
      ></div>
    </div>
    <div
      style="width: 100%; height: 164px"
      class="me-3 rounded-2 skeleton-item bg-black-200 mb-3"
    ></div>
    <div
      style="width: 100%"
      class="me-3 rounded-2 skeleton-item bg-black-200 h-100"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skeleton: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.skeleton {
  background-color: $black-400;
  margin-top: 6px;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 16px;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.822),
      transparent
    );
    top: 0;
    bottom: 0;
    animation: slide 1s infinite ease-out;
    transition: 0.3s;
  }
}
.skeleton-item {
  margin-top: 6px;
  position: relative;
  overflow: hidden;
  animation: skeleton 2s infinite ease-out;
  transition: 300ms;
  opacity: 1;
}
@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100%{
    opacity: 1;
  }
}
.table-container {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>